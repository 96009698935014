
import {
    ref,
    defineComponent,
    onMounted,
    onActivated,
    createVNode,
} from "vue";
import { _Client } from "@/api-client";
import { DeleteNonExaminingDateRequest, PagedResultOfSearchNonExaminingDateDto, SearchNonExaminingDateRequest } from "@/api-client/client";
import add from '@/views/availabilityManagement/noneExaminingDate/add.vue'
import modify from '@/views/availabilityManagement/noneExaminingDate/modify.vue'
import { Modal, message } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import Pagination from '@/components/Pagination/index.vue'

const today = new Date();
const currentYear = today.getFullYear();

const columns = [
    {
        title: 'Non-Examining Date',
        dataIndex: 'date',
    },
    {
        title: 'Reason',
        dataIndex: 'reason'
    },
    {
        title: 'status',
        dataIndex: 'isActive'
    },
    {
        title: 'Action',
        dataIndex: 'action',
        fixed: 'right',
        width: 200
    }
]

export default defineComponent({
    components: {
        add,
        modify,
        Pagination
    },
    setup() {
        const tableLoading = ref<boolean>(false)
        const showAdd = ref(false)
        const showModify = ref(false)
        const modifyId = ref('')

        const searchParames = ref<SearchNonExaminingDateRequest>(new SearchNonExaminingDateRequest({ year: currentYear, pageIndex: 1, pageSize: 10 }));
        const tableSource = ref(new PagedResultOfSearchNonExaminingDateDto())

        const change = (id: string) => {
            modifyId.value = id;
            showModify.value = true;
        }

        const close = (refresh: boolean) => {
            showModify.value = false;
            showAdd.value = false;

            if (refresh) {
                getList()
            }
        }

        const del = (id: string) => {
            Modal.confirm({
                title: 'Confirm',
                content: "Do you want to delete this Non-examining date?",
                icon: createVNode(ExclamationCircleOutlined),
                okText: "Delete",
                cancelText: "Cancel",
                onOk: () => {
                    tableLoading.value = true;
                    _Client.noneExaminingDateClient.delete(new DeleteNonExaminingDateRequest({ id: id })).then(_ => {
                        message.success('Operation Success.')
                        getList();
                    }).finally(() => tableLoading.value = false)
                },
            });
        }

        const getList = () => {
            tableLoading.value = true
            _Client.noneExaminingDateClient.getList(searchParames.value!).then(rep => {
                tableSource.value = rep
            }).finally(() => tableLoading.value = false)
        }

        const search = () => {
            searchParames.value.pageIndex = 1;
            searchParames.value.pageSize = 10;
        }

        const resetSearchParams = () => {
            searchParames.value.year = currentYear;
            searchParames.value.month = undefined;

            getList()
        }

        onActivated(() => {
            getList();
        });

        return {
            tableLoading,
            columns,
            tableSource,
            searchParames,
            currentYear,
            showAdd,
            showModify,
            modifyId,
            resetSearchParams,
            getList,
            search,
            change,
            close,
            del
        };
    }
});

